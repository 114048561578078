body {
  background-color: black;
}

#root {
  display: grid;
}

@media (width <= 800px) {
  #dreaming-site {
    justify-self: stretch;
  }
}

@media (width > 800px) {
  #dreaming-site {
    justify-self: center;
    width: 800px;
  }
}

#dreaming-site {
  min-width: 300px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;

  background-color: #F5F5F5; /* smoke white */ /* #FFFFFF; */
  border: 1px solid black;
}

#title-bar {
  font-size: 3em;
  width: 100%;
  grid-row: 1 / 2;
  grid-column: 1 / 4;

  display: grid;
  align-items: center;  /* vertical centering */
  justify-items: center;  /* horizontal centering */
  line-height: 1.4;

  background-color: #F5F5F5; /* smoke white */
}

#menu-bar {
  grid-row: 2 / 3;
  grid-column: 1 / 4;

  background-color: #000000;
  color: #FFFFFF;
  opacity: 0.8;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 3fr 1fr;
  line-height: 1.4;
}

#menu-items {
  grid-row: 1 / 2;
  grid-column: 2 / 3;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;

/*
  border-style: solid;
  border-width: 1px;
  border-color: black;
*/
}

.active-menu-item {
  color: aquamarine;
  cursor: pointer;
  text-decoration: none;
}

.menu-item {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

#Gallery-mi {
  grid-row: 1 / 2;
  grid-column: 1 / 2;

  display: grid;
  align-items: center;
  justify-items: center;
  /* background-color: blue; */
}

#About-mi {
  grid-row: 1 / 2;
  grid-column: 2 / 3;

  display: grid;
  align-items: center;
  justify-items: center;
  /* background-color: red; */
}

#Shop-mi {
  grid-row: 1 / 2;
  grid-column: 3 / 4;

  display: grid;
  align-items: center;
  justify-items: center;

  /*  background-color: green; */
}

/***** Gallery *****/

#gallery-content {
  grid-row: 3 / 4;
  grid-column: 1 / 4;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.4em;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
}

#bridge-title {
  font-size: 2em;
  padding-top: 0.4em;
  padding-bottom: 0.2em;
}

.bridge-name {
  font-size: 1.5em;
  text-align: center;
}

.bridge-seperator {
  border-top: 1px solid #C0C0C0;
  width: 90%;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.bridge {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
}

.gallery-img {
  width: 100%;
  height: auto;

  z-index: 1;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  box-sizing: border-box;
}

/***** About *****/

#about-content {
  padding-top: 0.4em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.4em;
  grid-column: 1 / 4;
/*
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto; */
}

#profile-img {
  float: left;
  width: 40%;
  height: auto;

  padding-top: 0.2em;
  padding-right: 1em;

/*
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  z-index: 1;
*/
}

#about-content-txt {
/*
  grid-column: 1 / 4;
  z-index: 1;
*/
}

#email {
  padding-top: 1em;
}

/***** Shop *****/

#shop-content {
  padding-top: 0.4em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.4em;
  grid-column: 1 / 4;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
}

#fremont-card {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;
  height: auto;

  box-sizing: border-box;
  padding: 0.05em;
/*  padding-top: 0.2em;
  padding-right: 1em; */
}

#jackson-card {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0.05em;

/*  padding-top: 0.2em;
  padding-right: 1em; */
}

#stjohns-card {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0.05em;

/*  padding-top: 0.2em;
  padding-right: 1em; */
}

#shop-content-txt {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
}

.shop-heading {
  font-size: 1.5em;
  text-align: center;
  padding-top: 0.5em;
}

.shop-content-copy {
  text-align: center;
}



/*
.gallery-img {
  grid-column: 1 / 3;

  width: 100%;
  height: auto;

  z-index: 1;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  box-sizing: border-box;
}

#steel-img {
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  padding-top: 0.5em;
}
*/
/*
#content-txt {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  z-index: 2;

  background-color: #b7ed0e;
  color: red;
  opacity: 0.5;

  text-align: right;

  margin-right: 0.5em; /* margin so background doesn't overflow
  padding-right: 0.2em;  /* extra padding so doesn't run to border
  margin-top: 0.5em; /* margin so background doesn't overflow
  margin-bottom: 0.25em; /* margin just so background doesn't overflow
  box-sizing: border-box;
}
*/
